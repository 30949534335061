import { Icon, IconProps } from '@node-real/icons';

export const TabTicked = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM12.3307 5.82572C12.5856 5.48585 12.5026 5.00163 12.1491 4.76598C11.8362 4.55738 11.4166 4.61662 11.1737 4.9037L7.20002 9.5999L4.92282 7.64801C4.62782 7.39516 4.18788 7.41205 3.91314 7.68679C3.62775 7.97218 3.62213 8.43311 3.90047 8.72537L6.58498 11.5441C7.01146 11.9919 7.73808 11.9492 8.10911 11.4544L12.3307 5.82572Z"
        fill="#F0B90B"
      />
    </Icon>
  );
};

export const TabUnTicked = (props: IconProps) => {
  return (
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5V17.5C13.6944 17.5 17.5 13.6944 17.5 9H16.5ZM9 16.5C4.85786 16.5 1.5 13.1421 1.5 9H0.5C0.5 13.6944 4.30558 17.5 9 17.5V16.5ZM1.5 9C1.5 4.85786 4.85786 1.5 9 1.5V0.5C4.30558 0.5 0.5 4.30558 0.5 9H1.5ZM9 1.5C13.1421 1.5 16.5 4.85786 16.5 9H17.5C17.5 4.30558 13.6944 0.5 9 0.5V1.5Z"
        fill="#2F3034"
      />
    </Icon>
  );
};
