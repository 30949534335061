import styled from '@emotion/styled';
import {
  mobileMedia,
  useGetPrice,
  useOngoingWithdraw,
  useResponsive,
} from '@op-bridge/bridge-core';
import { MenuCloseIcon, TransferIcon } from '@node-real/icons';
import { Box, Collapse, Flex } from '@node-real/uikit';
import { BN } from 'bn.js';
import { useCallback, useEffect, useState } from 'react';
import { useAccount } from 'wagmi';

import {
  addDataIntoCache,
  batchUpdate,
  deleteDataFromCache,
  divide10Exp,
  getDataFromCache,
} from '../../utils';
import { WithdrawActionButton } from '../button/WithdrawActionButton';
import { useL1Withdraw, useWithdrawConfirm } from '../../hooks';
import { TxError } from '../modal/TxError';
import { HistoryConfirmWithdraw } from '../modal/HistoryConfirmWithdraw';
import { ReturnAfter } from './ReturnAfter';
import { FINALIZE_WAITING_PERIOD, NET_ENV, PROVE_WAITING_PERIOD } from '../../env';
import { GA_MAP, reportEvent } from '../../utils/ga';
import { Remaining } from './Remaining';

export const OnGoingWithdraw = () => {
  const { address: wagmiAddress } = useAccount();
  const [address, setAddress] = useState<string>('');

  const [initiateHash, setInitHash] = useState(''); // Bep20 token or BNB initiate hash
  const [proveHash, setProveHash] = useState(''); // prove hash
  const [buttonSubmit, setButtonSubmit] = useState<number | null>(null);
  const [finalizeHash, setFinalizeHash] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [withdrawVal, setWithdrawVal] = useState(0);
  const [currentToken, setCurrentToken] = useState('BNB');

  const [historyCache, setHistoryCache] = useState<{ [key: string]: string } | null>(null);
  const [ongoingListOpen, setOngoingListOpen] = useState(false);
  const { data: txList, refetch } = useOngoingWithdraw(address);
  const { showWithdrawalConfirm, handleWithdrawToggle, handleWithdrawConfirmShow } =
    useWithdrawConfirm();

  const { isMobile } = useResponsive();

  const {
    proveWithdraw,
    finalWithdraw,
    txProveFailedMsg,
    setTxProveFailedMsg,
    txFinalizeFailedMsg,
    setTxFinalizeFailedMsg,
    showError,
    handleErrorShow,
  } = useL1Withdraw();

  const { data: bnbPrice } = useGetPrice('BNB');
  const { data: tokenPrice } = useGetPrice(currentToken);

  const getHistoryCache = useCallback(async () => {
    if (typeof window !== 'undefined') {
      const cache = await getDataFromCache(window.location.hostname, {
        deleteCallback: () => setButtonSubmit(null),
      });
      if (cache) {
        // console.log(cache);
        return cache;
      }
    }
  }, []);

  const getOngoingTabCache = useCallback(async () => {
    const cache = await getDataFromCache(window.location.hostname, {
      cacheName: 'ongoingOpen',
      deleteCacheWhenTimeOut: false,
    });
    return cache;
  }, []);

  useEffect(() => {
    getOngoingTabCache().then((res) => {
      if (res !== null && res?.tab !== null) {
        setOngoingListOpen(res?.tab);
      } else {
        // init tab
        isMobile ? setOngoingListOpen(false) : setOngoingListOpen(true);
      }
    });
  }, [getOngoingTabCache, isMobile]);

  useEffect(() => {
    refreshHistoryTable();

    // Set up an interval to load data every 5 seconds
    const intervalId = setInterval(() => {
      refreshHistoryTable();
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshHistoryTable = useCallback(async () => {
    refetch()
      .then(async () => {
        // const { data } = res;
        const cache = await getHistoryCache();
        batchUpdate(() => {
          if (cache) {
            setHistoryCache(cache);
          } else {
            setHistoryCache(null);
          }
        });
      })
      .catch((e: any) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [refetch, getHistoryCache]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      getHistoryCache().then((res: any) => {
        res ? setHistoryCache(res) : setHistoryCache(null);
      });
    }
  }, [getHistoryCache]);

  useEffect(() => {
    if (wagmiAddress) {
      setAddress(wagmiAddress);
    }
  }, [wagmiAddress]);

  useEffect(() => {
    if (txList?.total > 0) {
      reportEvent({
        name: GA_MAP.ongoingShow,
        data: { name: 'Ongoing Show' },
      });
    }
  }, [txList]);

  return txList?.transfers?.length > 0 ? (
    <Container>
      <CollapseButton
        cursor={'pointer'}
        fontSize={14}
        fontWeight={700}
        flex={'1 0 100%'}
        color={'#fff'}
        onClick={async () => {
          if (ongoingListOpen) {
            reportEvent({
              name: GA_MAP.ongoingHideClick,
              data: { name: 'Ongoing Close' },
            });
            setOngoingListOpen(false);
            await addDataIntoCache(window.location.hostname, { tab: false }, 'ongoingOpen');
          } else {
            setOngoingListOpen(true);
            await addDataIntoCache(window.location.hostname, { tab: true }, 'ongoingOpen');
          }
        }}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        lineHeight={'18px'}
      >
        <Flex alignItems={'center'} lineHeight={'normal'}>
          Ongoing Withdrawals <TitleCount>{txList?.total}</TitleCount>
        </Flex>
        <MenuCloseIcon
          color={'readable.pageButton'}
          w={24}
          h={24}
          mr={20}
          transform={ongoingListOpen ? 'rotate(-180deg)' : ''}
        />
      </CollapseButton>
      <Collapse
        color={'bg.card'}
        in={ongoingListOpen}
        overflow={txList?.transfers.length > 2 ? 'auto' : ''}
      >
        {txList?.transfers?.map((data: any) => {
          let disabled = false;
          try {
            // delete cache if status updated
            if (
              historyCache &&
              data.hash in historyCache &&
              (data?.receiptsStatus === 2 || data?.receiptsStatus === 4)
            ) {
              const cacheStatus = historyCache[`${data?.hash as string}`];
              if (data.receiptsStatus <= cacheStatus) {
                disabled = true;
              } else if (data?.receiptsStatus > cacheStatus) {
                // get updated status from backend, we can remove cache
                if (typeof window !== 'undefined') {
                  deleteDataFromCache(window.location.hostname, data?.hash);
                }
              }
            }
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
          }
          const status = data?.receiptsStatus;
          let valStr = '';
          if (data?.asset === 'BNB') {
            if (data?.isAuto) {
              if (data.amount.includes('0x')) {
                const valBn = new BN(data.amount.slice(2), 16);
                valStr = Number(divide10Exp(valBn, 18)).toLocaleString('fullwide', {
                  maximumFractionDigits: 18,
                });
              } else {
                valStr = (Number(data.amount) * Math.pow(10, -18)).toLocaleString('fullwide', {
                  maximumFractionDigits: 18,
                });
              }
            } else {
              if (data.value.includes('0x')) {
                const valBn = new BN(data.value.slice(2), 16);
                valStr = Number(divide10Exp(valBn, 18)).toLocaleString('fullwide', {
                  maximumFractionDigits: 18,
                });
              } else {
                valStr = (Number(data.value) * Math.pow(10, -18)).toLocaleString('fullwide', {
                  maximumFractionDigits: 18,
                });
              }
            }
          } else {
            /**
             * Bep20 Token Amount
             */
            if (data?.amount !== null || data?.amount !== undefined) {
              const BNvalue = new BN(data?.amount.replace('0x', ''), 16);
              const amount = Number(divide10Exp(BNvalue, 18));
              valStr = Number(amount).toLocaleString('fullwide', {
                maximumFractionDigits: 18,
              });
            }
          }

          return (
            <Flex
              padding={'12px 0'}
              borderTop={'1px solid'}
              borderColor={'readable.border'}
              color={'#fff'}
              alignItems={'center'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              marginRight={15}
              key={data.hash}
            >
              <Flex flexDirection={'row'} alignItems={'center'}>
                {!isMobile ? (
                  <TransferIcon color={'readable.disabled'} h={24} w={24} mr={8} />
                ) : null}
                <Box>
                  <Box mb={2}>
                    Withdraw {valStr} {data?.asset || 'BNB'}
                  </Box>
                  <CountDownTimerWrapper
                    type={status === 2 || status === 4 ? 'ready' : 'countdown'}
                  >
                    {status === 2 ? (
                      disabled ? (
                        'Proving Withdrawal'
                      ) : (
                        'Ready to Prove'
                      )
                    ) : status === 4 ? (
                      disabled ? (
                        'Finalizing Withdrawal'
                      ) : (
                        'Ready to Finalize'
                      )
                    ) : // Manual withdrawal
                    data?.statusChangeTimeStamp && data?.isAuto !== true ? (
                      <ReturnAfter
                        time={
                          status === 3
                            ? (data?.statusChangeTimeStamp + FINALIZE_WAITING_PERIOD[NET_ENV]) *
                              1000
                            : (data?.statusChangeTimeStamp + PROVE_WAITING_PERIOD[NET_ENV]) * 1000
                        }
                        status={status}
                      />
                    ) : // Auto withdrawal
                    data?.statusChangeTimeStamp && data?.isAuto === true ? (
                      <Remaining
                        time={
                          status === 3
                            ? (data?.statusChangeTimeStamp + FINALIZE_WAITING_PERIOD[NET_ENV]) *
                              1000
                            : (data?.statusChangeTimeStamp +
                                (NET_ENV === 'Testnet' ? PROVE_WAITING_PERIOD[NET_ENV] : 0) +
                                FINALIZE_WAITING_PERIOD[NET_ENV]) *
                              1000
                        }
                      />
                    ) : (
                      ''
                    )}
                  </CountDownTimerWrapper>
                </Box>
              </Flex>

              <WithdrawActionButton
                data={data}
                proveWithdraw={proveWithdraw}
                finalWithdraw={finalWithdraw}
                disabled={disabled}
                setButtonSubmit={setButtonSubmit}
                setWithdrawVal={setWithdrawVal}
                setProveHash={setProveHash}
                setFinalizeHash={setFinalizeHash}
                setInitHash={setInitHash}
                setCurrentToken={setCurrentToken}
                setIsConfirming={setIsConfirming}
                handleWithdrawToggle={handleWithdrawToggle}
                refreshHistoryTable={refreshHistoryTable}
                place="ongoing"
              />
            </Flex>
          );
        })}
      </Collapse>

      {showWithdrawalConfirm && (
        <HistoryConfirmWithdraw
          withdrawVal={withdrawVal}
          tokenName={currentToken}
          isOpen={showWithdrawalConfirm}
          handleOpen={(open: boolean) => {
            handleWithdrawConfirmShow(open);
            setButtonSubmit(null);
          }}
          buttonSubmit={buttonSubmit}
          setButtonSubmit={setButtonSubmit}
          bnbPrice={bnbPrice}
          tokenPrice={tokenPrice}
          initiateWithdrawHash={initiateHash}
          proveWithdrawHash={proveHash}
          finalizeWithdrawHash={finalizeHash}
          isInitConfirming={isConfirming}
          historyCache={historyCache}
          historyList={txList?.transfers}
          isHistoryPage={false}
        />
      )}

      {txProveFailedMsg && (
        <TxError
          title={`Prove Withdrawal Failed`}
          isOpen={showError}
          handleOpen={() => {
            setTxProveFailedMsg('');
            handleErrorShow(false);
          }}
          errorCode={txProveFailedMsg}
        />
      )}

      {txFinalizeFailedMsg && (
        <TxError
          title={`Finalize Withdrawal Failed`}
          isOpen={showError}
          handleOpen={() => {
            setTxFinalizeFailedMsg('');
            handleErrorShow(false);
          }}
          errorCode={txFinalizeFailedMsg}
        />
      )}
    </Container>
  ) : null;
};

const Container = styled(Flex)`
  max-height: 200px;
  flex-direction: column;
  padding: 16px 4px 0 24px;
  z-index: 1;
  border-radius: 8px;
  margin: 32px 32px -15px;
  background: ${(props: any) => props.theme.colors.bg?.card};
  color: ${(props: any) => props.theme.colors.readable?.normal};
  ${mobileMedia} {
    margin: 8px 12px -16px;
    padding: 16px 12px 0;
  }
`;

const CountDownTimerWrapper = styled(Box)<{ type: 'countdown' | 'ready' }>`
  color: ${(props: any) =>
    props.type === 'ready'
      ? props.theme.colors.readable?.pageButton
      : props.theme.colors.scene?.orange?.normal};
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
`;

const CollapseButton = styled(Flex)`
  margin-bottom: 16px;
`;

const TitleCount = styled(Flex)`
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  margin-left: 2px;
  background: ${(props: any) => props.theme.colors.scene.primary.normal};
  color: ${(props: any) => props.theme.colors.bg?.card};
  height: 18px;
  min-width: 16px;
  border-radius: 12px;
  padding: 0 4.5px;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
`;
