import styled from '@emotion/styled';
import { mobileMedia } from '@op-bridge/bridge-core';
import { Box, Flex, Tab, TabList } from '@node-real/uikit';

import { TabTicked, TabUnTicked } from '../svgIcon/TabTick';

export const TAB_MAP = ['thirdParty', 'official'];

export const WithdrawTabList = () => {
  // const { isMobile } = useResponsive();
  return (
    <TabsWrapper>
      <CustomTabList>
        <StyledTab tabKey={TAB_MAP[0]}>
          <Flex
            mb={4}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={['column-reverse', 'row']}
          >
            <Title className="title" mt={[8, 0]}>
              Third Party Bridge
            </Title>
            <TabUnTicked className="not-ticked" width={16} height={16} />
            <TabTicked className="ticked" width={16} height={16} />
          </Flex>
          <Description>
            Independent service providers: it usually takes about 5 minutes to complete a
            withdrawal.
          </Description>
        </StyledTab>
        <StyledTab ml={8} tabKey={TAB_MAP[1]}>
          <Flex
            mb={4}
            w={'100%'}
            alignItems={'center'}
            justifyContent={'space-between'}
            flexDirection={['column-reverse', 'row']}
          >
            <Title className="title" mt={[8, 0]}>
              Official Bridge
            </Title>
            <TabUnTicked className="not-ticked" width={16} height={16} />
            <TabTicked className="ticked" width={16} height={16} />
          </Flex>
          <Description>
            Utilizing the native bridge capacity of opBNB. It usually takes about 7 days to complete
            a withdrawal.
          </Description>
        </StyledTab>
      </CustomTabList>
    </TabsWrapper>
  );
};

const Title = styled(Box)`
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
`;

const Description = styled.div`
  color: ${(props: any) => props.theme.colors.readable.secondary};
  font-family: Space Grotesk;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  height: 45px;
  ${mobileMedia} {
    text-align: center;
  }
`;

const TabsWrapper = styled(Flex)`
  padding: 0;
`;

const CustomTabList = styled(TabList)`
  gap: 0;
  width: 100%;
  justify-content: space-between;
`;

const StyledTab = styled(Tab)`
  font-family: Space Grotesk;
  flex: 1 0;
  flex-basis: calc(50% - 4px);
  position: relative;
  border-radius: 4px;
  flex-direction: column;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0px;
  padding: 12px;
  height: 91px;
  color: ${(props: any) => props.theme.colors.readable.secondary};
  border: 1px solid ${(props: any) => props.theme.colors.readable.border};
  &[data-selected] {
    border: 1px solid ${(props: any) => props.theme.colors.scene.primary.normal};
    background-color: ${(props: any) => props.theme.colors.bg.card};
    .ticked {
      display: block;
    }
    .not-ticked {
      display: none;
    }
    .title {
      ${(props: any) => props.theme.colors.scene.primary.normal};
    }
    &::after {
      background-color: ${(props: any) => props.theme.colors.bg.card};
      content: '';
      position: absolute;
      top: 94%;
      border: solid ${(props: any) => props.theme.colors.scene.primary.normal};
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 6px;
      transform: rotate(45deg);
    }
  }
  &:not([data-selected]) {
    .title {
      color: #ffffff;
    }
    .ticked {
      display: none;
    }
    .not-ticked {
      display: block;
    }
  }

  &:hover {
    background-color: ${(props: any) => props.theme.colors.bg.card};
  }
  ${mobileMedia} {
    font-size: 16px;
    justify-content: center;
    padding: 12px 8px;
    height: 147px;
    justify-content: flex-start;
    &[data-selected] {
      &::after {
        top: 96%;
      }
    }
  }
`;
